<template>
	<SSOConfigurationLayout
		v-if="ssoConfigurations.length > 0 && filteredSSOConfiguration.length > 0"
		title="SSO Configuration"
		caption="Manage SSO Configuration for the Cassie Admin Portal & Customer Service Portal."
	>
		<template #content>
			<SectionCard>
				<template #title>
					SSO Configuration
				</template>
				<template #body>
					<DataTable
						:headers="tableHeaders"
						:items="filteredSSOConfiguration"
						hide-default-footer
						item-key="productId"
						@click:row="configureSSO"
					>
						<template #item.action="{ item }">
							<IconButton
								v-if="showConfigureIcon(item.productId) && userFullPermissions"
								:test-id="`configure-${getSSOTestId(item.productName)}-sso`"
								tooltip-text="Configure SSO"
								@click="configureSSO(item)"
							>
								mdi-pencil
							</IconButton>
							<IconButton
								v-else-if="showConfigureIcon(item.productId) && !userFullPermissions"
								:test-id="`view-${getSSOTestId(item.productName)}-sso-configuration`"
								tooltip-text="View SSO Configuration"
								@click="configureSSO(item)"
							>
								mdi-eye
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
		</template>
	</SSOConfigurationLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import SSOConfigurationLayout from './sso-configuration-layout.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { getSSOConfiguration } from '../../../../../../shared/utils/api/sso-configuration.js'
import { SSO_CONFIGURATION_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { MANAGE_SSO_CONFIGURATION } from '../../../../router/route-names.js'
export default {
	components: {
		SSOConfigurationLayout,
		SectionCard,
		DataTable,
		IconButton
	},
	data () {
		const ADMIN_PORTAL = 1
		const CS_PORTAL = 2
		return {
			ssoConfigurations: [],
			lookupData: {},
			ADMIN_PORTAL,
			CS_PORTAL
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		tableHeaders () {
			return [
				{
					text: 'SSO Link Name',
					value: 'name'
				},
				{
					text: 'Product',
					value: 'productName'
				},
				{
					text: 'Created Date',
					value: 'createdDate'
				},
				{
					text: 'Action',
					value: 'action',
					sortable: false
				}
			]
		},
		userFullPermissions () {
			return this.productAreaPermission(SSO_CONFIGURATION_FULL_PERMISSIONS)
		},
		filteredSSOConfiguration () {
			const adminPortalSSO = this.ssoConfigurations.filter(sso => sso.productId === this.ADMIN_PORTAL)
			const csPortalSSO = this.ssoConfigurations.filter(sso => sso.productId === this.CS_PORTAL)

			const activeAdminPortalSSO = adminPortalSSO.filter(sso => sso.isEnabled)
			const activeCsPortalSSO = csPortalSSO.filter(sso => sso.isEnabled)

			const ssoConfigurations = []
			if (activeAdminPortalSSO.length === 1) {
				ssoConfigurations.push(activeAdminPortalSSO[0])
			} else if (adminPortalSSO.length >= 2) {
				const mostRecent = adminPortalSSO.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
				ssoConfigurations.push(mostRecent[0])
			} else {
				ssoConfigurations.push(adminPortalSSO[0])
			}

			if (activeCsPortalSSO.length === 1) {
				ssoConfigurations.push(activeCsPortalSSO[0])
			} else if (csPortalSSO.length >= 2) {
				const mostRecent = csPortalSSO.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
				ssoConfigurations.push(mostRecent[0])
			} else {
				ssoConfigurations.push(csPortalSSO[0])
			}

			return ssoConfigurations ?? []
		}
	},
	async created () {
		await this.getSSOConfiguration()
	},
	methods: {
		async getSSOConfiguration () {
			const { data: { ssoConfigurations, lookupData } } = await getSSOConfiguration()
			this.ssoConfigurations = ssoConfigurations
			this.lookupData = lookupData
		},
		getSSOTestId (productName) {
			return productName.toLowerCase().replace(/ /g, '-')
		},
		showConfigureIcon (productId) {
			if (productId === this.ADMIN_PORTAL) {
				return true
			}	else if (productId === this.CS_PORTAL && this.lookupData?.csPortalIsAllowed) {
				return true
			} else {
				return false
			}
		},
		configureSSO (item) {
			this.$router.push({
				name: MANAGE_SSO_CONFIGURATION,
				params: {
					ssoConfigurationToManage: item,
					lookupData: this.lookupData
				}
			})
		}
	}
}
</script>
